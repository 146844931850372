import React, { useState } from 'react'
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import AnimationWrapper from "../components/animationWrapper"
import ContactSection from "../components/contactSection"
import Container from "../components/container"
import Header from "../components/header"
import TextSection from "../components/textSection"
import TilesSection from "../components/tilesSection"
import Title from "../components/title"
import Layout from "../components/layout"

import "./wykonawcy.css"

const WykonawcyPage = ({data, location}) => {

  // set meta description value
  let metaDescription;
  if (data.page.frontmatter.meta_description){
    metaDescription = data.page.frontmatter.meta_description;
  } else {
    metaDescription = `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Sprawdź!`;
  }

  // sort contractors by sales people
  const sortedContractors = {}; // Create a new object to hold updated keys
  const salesPeople = data.sales_people.nodes;

  data.contractors.group.forEach(function(group) {
    salesPeople.forEach(function(salesPerson) {
      if (group.edges[0].node.frontmatter.contact_sales === salesPerson.frontmatter.slug) {
        if (!sortedContractors[salesPerson.frontmatter.slug]) {
          sortedContractors[salesPerson.frontmatter.slug] = []; // Initialize an empty array if not exists
        }
        sortedContractors[salesPerson.frontmatter.slug].push({
          name: "Wykonawca",
          phone: "Nr kontaktowy",
          location: "Teren działania",
          specialisations: [ "Zakres działań" ],
        })
        group.edges.forEach(function(groupedContractors) {
          const contractorInfo = {
            ...groupedContractors.node.frontmatter, // Spread frontmatter properties
            specialisations: ["Usługi ogólnobudowlane"], // override specialisations
            overlay_image: groupedContractors.node.frontmatter.certificate,
            sales_person: salesPerson.frontmatter.name,
            region: salesPerson.frontmatter.region,
            position: salesPerson.frontmatter.position,
            email: salesPerson.frontmatter.email,
            sales_phone: salesPerson.frontmatter.phone,
            sales_phone_alt: salesPerson.frontmatter.phone_alt,
            phone_postfix: salesPerson.frontmatter.phone_postfix,
            avatar: salesPerson.frontmatter.avatar,
            active: true // Add active prop
          };
          sortedContractors[salesPerson.frontmatter.slug].push(contractorInfo); // Push contractor info to the array
        });
      }
    });
  });

  // State to track visible section
  const [visibleSection, setVisibleSection] = useState(null);
  // Function to toggle visibility of sections
  const toggleSection = (group) => {
    setVisibleSection(group === visibleSection ? null : group);
  };

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper>

      {data.page.frontmatter.text_section_main.active &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_section_main} />
          </AnimationWrapper>
        </Container>
      }

      <section id="contractors-tiles-section">
        <Container>
          <AnimationWrapper className="contractors-title-wrapper">
            <Title className="contractors-title" title="Kliknij na region aby rozwinąć listę polecanych wykonawców:" />
          </AnimationWrapper>
        </Container>
        {Object.keys(sortedContractors).map((group) => (
          <div className={`contractors-section contractors-${group}`} key={group}>
            <div className="title-container" onClick={() => toggleSection(group)} onKeyDown={() => toggleSection(group)} role='presentation'>
                {sortedContractors[group][1].region &&
                  <div className="contractors-region" dangerouslySetInnerHTML={{ __html: sortedContractors[group][1].region }} />
                }
            </div>
            <TilesSection
              type="data"
              location="contractors"
              data={sortedContractors[group]}
              state={visibleSection === group ? "show" : "hide"} // Conditionally apply "show" class
            />
          </div>
        ))}
      </section>

      <section id="contact-data" className="page-section">
        <ContactSection data={data.contactSection.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const query = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "wykonawcy" }, type: { eq: "page" } }) {
      frontmatter{
        title
        meta_description
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_section_main{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
        icons{
          title
          text
          image{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    contractors: allMdx(
      filter: {frontmatter: {type: {eq: "contractor"}}}
      sort: { fields: [frontmatter___location], order: ASC }
    ) {
        group(field: frontmatter___contact_sales) {
          edges {
            node {
              frontmatter {
                name
                order
                phone
                location
                contact_sales
                certificate{
                  childImageSharp {
                    gatsbyImageData(
                      transformOptions: {fit: COVER},
                      webpOptions: {quality: 95},
                      height: 500
                    )
                  }
                }
                specialisations
              }
            }
        }
      }
    }
    sales_people: allMdx(
      filter: {frontmatter: {department: {in: ["dzial-handlowy", "partnerzy-handlowi"]}, slug: {ne: "anna-balekowska"}}}
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      nodes {
        frontmatter {
          name
          slug
          order
          position
          email
          phone
          phone_alt
          phone_postfix
          region
          avatar {
            childImageSharp {
              gatsbyImageData(
                webpOptions: {quality:95}
                width: 110
                height: 110
              )
            }
          }
        }
      }
    }
    contactSection: mdx(
      frontmatter: {slug: {eq: "contact-section"}, type: {eq: "global"}}
    ){
      frontmatter{
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          image_object_fit
          image_object_position
        }
      }
    }
  }
`

export default WykonawcyPage